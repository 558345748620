import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Typography } from '../../shared/core/typography/Typography';
import { media, colors } from '../../shared/core/styles';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { useLayoutDesignContext } from '../../../utilities/context/dynamic/LayoutDesignContext';

const S = {
  InfoContainer: styled.div`
    padding: 8% 0;
    width: 100%;
    display: flex;
    justify-content: center;
    order: 2;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      order: 1;
    }
  `,

  InfoContainerText: styled.div`
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      text-align: start;
    }

    @media ${({ theme }) => media(theme).between('sm', 'lg')} {
      padding: 0 10%;
      width: 375px;
    }

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      width: 420px;
      align-items: flex-start;
    }
  `,

  Title: styled(Typography)`
    padding-bottom: 9px;
    text-transform: capitalize;
  `,

  Description: styled.div<{ $hasProductAccordions?: boolean }>`
    margin-bottom: 13px;
    font-size: 13px;
    color: ${({ $hasProductAccordions }) =>
      $hasProductAccordions ? colors.NERO_BLACK : colors.ACCESSIBILITY_GREY};

    & > div > a {
      font-size: 14px;
      font-weight: 700;
      color: ${colors.NERO_GREY};
      text-decoration: none;

      &:hover,
      &:focus {
        color: ${colors.ACCESSIBILITY_GREY};
      }

      &::before {
        font-family: gstar-icons;
        content: '\\F111';
        margin: 0 9px 0 0 !important;
        font-size: 0.5em !important;
        line-height: 0 !important;
        width: 1em;
        vertical-align: middle;
      }
    }
  `,
};

export const ExplodedViewInfo = (): ReactElement => {
  const {
    product: { explodingView },
  } = usePDPContext();
  const { hasProductAccordions } = useLayoutDesignContext();

  const explodingViewDescription = explodingView?.explodingViewDescription;
  const explodingViewTitle = explodingView?.explodingViewTitle;

  if (hasProductAccordions) {
    return (
      <>
        {explodingViewTitle && (
          <S.Title
            variant="h1"
            testId="exploding-view-title"
            data-cs-capture=""
            data-accordion-title
          >
            {explodingViewTitle}
          </S.Title>
        )}
        {explodingViewDescription && (
          <S.Description
            data-testid="exploding-view-description"
            dangerouslySetInnerHTML={{ __html: explodingViewDescription }}
            data-cs-capture=""
            $hasProductAccordions={hasProductAccordions}
          />
        )}
      </>
    );
  }

  return (
    <S.InfoContainer>
      <S.InfoContainerText>
        {explodingViewTitle && (
          <S.Title variant="h1" testId="exploding-view-title" data-cs-capture="">
            {explodingViewTitle}
          </S.Title>
        )}
        {explodingViewDescription && (
          <S.Description
            data-testid="exploding-view-description"
            dangerouslySetInnerHTML={{ __html: explodingViewDescription }}
            data-cs-capture=""
          />
        )}
      </S.InfoContainerText>
    </S.InfoContainer>
  );
};
