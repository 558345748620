import type { ReactElement } from 'react';
import SustainabilityTraceabilityIcon from '../../shared/core/icons/SustainabilityTraceability';
import type { ImporterDetails as ImporterDetailsSchema } from '../../../amplienceTypes/schemas/imported/product-sustainability-traceability-schema';

import { S } from './styles';
import { useAppContext } from '../../../utilities/context/static/AppContext';

interface ImporterDetailsProps {
  schemaData?: ImporterDetailsSchema;
}

export const ImporterDetails = ({ schemaData }: ImporterDetailsProps): ReactElement => {
  const { country } = useAppContext();

  if (!schemaData || !(schemaData?.title && schemaData?.details)) {
    return <></>;
  }

  const { restrictions } = schemaData;

  if (!(restrictions && restrictions.length && restrictions.search(country.toUpperCase()) > -1)) {
    return <></>;
  }

  return (
    <S.Container>
      <S.Header variant="h5" component="h6" data-testid="importer-info">
        <SustainabilityTraceabilityIcon type="G" />
        {schemaData?.title}
      </S.Header>
      <S.Layout
        data-testid="importer-details"
        $size="default"
        dangerouslySetInnerHTML={{ __html: schemaData.details?.html?.html || '' }}
      />
    </S.Container>
  );
};
