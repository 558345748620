/* eslint-disable react/no-danger */
import type { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import styled from 'styled-components';
import { colors } from '../../shared/core/styles';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { WashingLabels } from '../productInfo/WashingLabels';
import { useAppContext } from '../../../utilities/context/static/AppContext';

const S = {
  Wrapper: styled.div`
    h3 {
      font-size: 14px;
      line-height: 26px;
      font-weight: 700;
      margin: 8px 0;
      margin-bottom: 0;
    }

    p:not(:last-of-type) {
      margin-bottom: 8px;
    }
  `,

  List: styled.ul`
    padding: 0;
    margin: 0 0 13px 16px;
    line-height: 20px;

    a {
      color: ${colors.NERO_BLACK};
      text-decoration: underline;

      &:hover,
      &:focus {
        color: ${colors.NERO_GREY};
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  `,

  Link: styled(Link)`
    color: ${colors.NERO_GREY};
    margin-bottom: 8px;
    text-decoration: underline;
    text-transform: capitalize;
  `,
};

interface ProductDescriptionCareProps {
  title: string;
  materialsTitle: string;
  colorTitle: string;
  cleverCareLink: string;
}

export const ProductDescriptionCare = ({
  title,
  materialsTitle,
  colorTitle,
  cleverCareLink,
}: ProductDescriptionCareProps): ReactElement => {
  const { t } = useTranslation('pdp', { keyPrefix: 'productInfo' });

  const {
    product: {
      featuresIntro,
      detailsBullets,
      programTitle,
      programDescription,
      fabricBullets,
      color,
      washingLabels,
      code,
    },
  } = usePDPContext();
  const { locale } = useAppContext();

  return (
    <S.Wrapper>
      <h2 data-accordion-title>{title}</h2>
      <p data-testid="features-intro">{featuresIntro}</p>
      {Array.isArray(detailsBullets) && Boolean(detailsBullets.length) && (
        <S.List>
          {detailsBullets.map((e, i) => (
            <li key={i} dangerouslySetInnerHTML={{ __html: e || '' }} />
          ))}
        </S.List>
      )}
      {programTitle && <h3 data-testid="program-title">{programTitle}</h3>}
      {programDescription && <p data-testid="program-desc">{programDescription}</p>}
      {materialsTitle && <h3 data-testid="material-title">{materialsTitle}</h3>}
      {Array.isArray(fabricBullets) && Boolean(fabricBullets.length) && (
        <S.List>
          {fabricBullets.map((e, i) => (
            <li key={i} dangerouslySetInnerHTML={{ __html: e || '' }} />
          ))}
        </S.List>
      )}
      {colorTitle && color?.description && (
        <>
          <h3 data-testid="color-finish-title">{colorTitle}</h3>
          <S.Link
            href={`/${locale}/shop/all?exactColorDescription=${color?.description?.toLowerCase()}`}
            data-testid="color-finish-desc"
            passHref
          >
            {color?.description}
          </S.Link>
        </>
      )}
      {Array.isArray(washingLabels) && Boolean(washingLabels.length) && (
        <WashingLabels title={t('washingInstructionsTitle')} cleverCareLink={cleverCareLink} />
      )}
      <p data-testid="art-number">
        {t('artNr')}: {code}
      </p>
    </S.Wrapper>
  );
};
